import LinkComponent from '../LinkComponent/LinkComponent'
import ReactMarkdown from 'react-markdown'
import content from '../../content/get-involved'
import GettingInvolvedPuzzleSlider from './GettingInvolvedSlider'
import './GettingInvolvedPuzzles.scss'

const {
  title,
  description_markdown,
  cta: { label, link },
  policy_markdown,
  puzzles: { items },
} = content.puzzle_section

const ALLOWED_ELEMENTS = ['a', 'p', 'em', 'strong', 'ul', 'ol', 'li', 'br']

const GettingInvolvedPuzzles = () => {
  if (!items) {
    return null
  }

  return (
    <section
      className="GettingInvolvedPuzzles"
      data-testid="getting-involved-puzzle-section"
    >
      <div className="GettingInvolvedPuzzles__container">
        <div className="GettingInvolvedPuzzles__info">
          <h2>{title}</h2>
          <ReactMarkdown
            className="GettingInvolvedPuzzles__description"
            allowedElements={ALLOWED_ELEMENTS}
          >
            {description_markdown}
          </ReactMarkdown>
          <LinkComponent href={link} addClass="GettingInvolvedPuzzles__cta">
            <span>{label}</span> <i className="bx bx-chevron-right" />
          </LinkComponent>
          <ReactMarkdown
            className="GettingInvolvedPuzzles__policy"
            allowedElements={ALLOWED_ELEMENTS}
          >
            {policy_markdown}
          </ReactMarkdown>
        </div>
        <GettingInvolvedPuzzleSlider />
      </div>
    </section>
  )
}

export default GettingInvolvedPuzzles
