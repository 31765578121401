import Seo from '../components/seo'
import GettingInvolvedHero from '../components/GettingInvolvedHero/GettingInvolvedHero'
import SpotlightSection from '../components/SpotlightSection/SpotlightSection'
import GettingInvolvedInfoSections from '../components/GettingInvolvedInfoSections/GettingInvolvedInfoSections'
import GettingInvolvedContactSection from '../components/GettingInvolvedContactSection/GettingInvolvedContactSection'
import GettingInvolvedPuzzles from '../components/GettingInvolvedPuzzles/GettingInvolvedPuzzles'
import TripleCardSection from '../components/TripleCardSection/TripleCardSection'
import content from '../content/get-involved'

import '../scss/pages/get-involved.scss'

const {
  meta_title,
  meta_description,
  meta_image,
  spotlight_section,
  community,
} = content

export default function GetInvolvedPage() {
  const hasAnnouncements = spotlight_section.announcements.length > 0
  return (
    <div className="GetInvolvedPage">
      <GettingInvolvedHero />
      {hasAnnouncements && (
        <SpotlightSection announcements={spotlight_section.announcements} />
      )}
      <GettingInvolvedInfoSections />
      <TripleCardSection
        title={community.title}
        description={community.description}
        cards={community.cards}
        background_image={community.background_image}
        background_colour={community.background_colour}
      />
      <GettingInvolvedPuzzles />
      <GettingInvolvedContactSection />
    </div>
  )
}

export const Head = () => (
  <Seo title={meta_title} description={meta_description} image={meta_image} />
)
