import puzzlesWithSolutions from './puzzles'

export default {
  /* Meta Data */
  meta_title: 'Get Involved — PennyLane',
  meta_description:
    'A global quantum community. Open-source code. What part will you play?',
  meta_image:
    'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/open_graph.png',

  /* Hero */
  hero: {
    title: 'Program the quantum future, together.',
    description:
      'Welcome to a global quantum community that’s been open-source since day one, where everyone can make a unique contribution to the future. What part will you play?',
    cta: {
      label: 'Install PennyLane',
      link: '/install',
    },
    highlights: {
      title: 'Highlights',
      items: [
        {
          title:
            "Join us at the Xanadu-sponsored FTQT 2024 workshop in Benasque, August 4th–17th",
          image:
            'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/thumbs/megaphone_thumbnail.png',
          link: 'http://benasque.org/2024ftqt/',
          date: 'Jul 29, 2024',
        },
        {
          title:
            'Join the Womanium Quantum + AI Program and win a unique PennyLane Certificate',
          image:
            'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/thumbs/challenges_thumbnail.png',
          link: 'https://lu.ma/WQ-2024',
          date: 'Jul 17, 2024',
        },
        {
          title:
            `See how team LearnQPE extended their QHack 2024 project and applied the quantum-train technique to flood prediction`,
          image:
            'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/thumbs/papers_thumbnail.png',
          link: 'https://arxiv.org/abs/2407.08617',
          date: 'Jul 12, 2024',
        },
        {
          title:
            'QHack 2023 Flashback is now over — check out some of the solutions from our community ❤',
          image:
            'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/thumbs/videos_thumbnail.png',
          link: 'https://www.youtube.com/playlist?list=PLhI5X1mNN8gihWCaMr82cyAjBy4mVRHCV',
          date: 'Jul 02, 2024',
        },
      ],
    },
  },

  /* Spotlight */
  spotlight_section: {
    announcements: [
      // {
      //   title: 'PENNYLANE SPRING BADGE CHALLENGE',
      //   description:
      //     'April 11–30 | Complete all beginner & novice challenges to earn the *Good Day Sunshine* limited-edition badge.',
      //   image: 'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_PLBadge_GoodDaySunshine_2024-04-04.png',
      //   image_right_aligned: false,
      //   alt: '',
      //   links: [
      //     {
      //       link_text: 'Earn this limited-edition badge now',
      //       link: 'https://pennylane.ai/challenges/',
      //     },
      //     {
      //       link_text: 'Prep with the Getting Started challenge',
      //       link: 'https://pennylane.ai/challenges/getting_started/',
      //     },
      //   ],
      //   colours: {
      //     border: '#CC00CC',
      //     background: '#FDFAFD',
      //     title: '#550055',
      //     description: '#012343',
      //     link: '#550055',
      //   },
      // },
      // {
      //   title: 'PENNYLANE SPRING BADGE CHALLENGE',
      //   description:
      //     'April 11–30 | Complete all intermediate & advanced challenges to earn the *Rain, Shine, Bloom* limited-edition badge.',
      //   image: 'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_PLBadge_RainShineBloom_2024-04-04.png',
      //   image_right_aligned: true,
      //   alt: '',
      //   links: [
      //     {
      //       link_text: 'Earn this limited-edition badge now',
      //       link: 'https://pennylane.ai/challenges/',
      //     },
      //     {
      //       link_text: 'Prep with the Getting Started challenge',
      //       link: 'https://pennylane.ai/challenges/getting_started/',
      //     },
      //   ],
      //   colours: {
      //     border: '#0274DE',
      //     background: '#F7FAFC',
      //     title: '#0271D9',
      //     description: '#002445',
      //     link: '#0271D9',
      //   },
      // },
    ],
  },

  /* Info Sections */
  info_sections: [
    {
      title: 'Community Demos',
      image:
        'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/community_demos.png',
      description:
        'You’re contributing to the future of quantum computing — and we don’t take that lightly. That’s why we have a dedicated place to feature demos created by the PennyLane Community. Why not submit one of your own?',
      cta: {
        label: 'Submit a demo',
        link: '/qml/demos_submission/',
      },
    },
    {
      title: 'Contribute to PennyLane',
      image:
        'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/community_connection.png',
      description:
        'PennyLane is community-focused — we want to build a framework that works for you, and the best way for that to happen is for you to contribute. Development of PennyLane takes place publicly on GitHub and we want you to join us.',
      cta: {
        label: 'PennyLane GitHub',
        link: 'https://github.com/PennyLaneAI/pennylane',
      },
    },
  ],

  /* Puzzles Section */
  puzzle_section: {
    title: 'Quantum Puzzles',
    description_markdown: `Your dose of quantum joy and the perfect icebreaker for your next talk. \n \n Want to use these in your next presentation or talk?`,
    cta: {
      label: 'Download the puzzles here.',
      link: 'https://drive.google.com/drive/folders/1545zM9FemkzronxaBG2xJqG_MUt2RFAe?usp=sharing',
    },
    policy_markdown: `These puzzles are licensed under [CC BY-ND 4.0](http://creativecommons.org/licenses/by-nd/4.0/).`,
    /* Quantum Puzzles */
    puzzles: {
      input_label: 'Your answer',
      submit_label: 'Check answer',
      next_slide_label: 'Next puzzle',
      correct_message: 'Correct!',
      incorrect_message: 'Incorrect. Try again!',
      solution_label: 'See solution',
      items: puzzlesWithSolutions,
    },
  },

  /* Community */
  community: {
    background_image:
      'https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/confetti_magenta.png',
    background_colour: '#7F007F',
    title: 'Talk to the Community',
    description: `Get started on your journey or share your expertise with the community.`,
    cards: [
      {
        title: 'Forum',
        description: 'Have a technical question? Join our discussion forum.',
        cta: {
          label: 'Ask a question',
          link: 'https://discuss.pennylane.ai/',
        },
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/icons/forum_illustration.png',
      },
      {
        title: 'Slack',
        description: 'Engage with a global quantum community.',
        cta: {
          label: 'Connect now',
          link: 'https://join.slack.com/t/xanadu-quantum/shared_invite/zt-1i8v8v49d-S76QxXm3OKCm9g0bvWvDpg',
        },
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/icons/slack_illustration.png',
      },
      {
        title: 'Discord',
        description: 'Meet and chat with other PennyLane users.',
        cta: {
          label: 'Join the conversation',
          link: 'https://discord.com/invite/gnySM3nrN3',
        },
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/icons/discord_illustration.png',
      },
    ],
  },

  /* Contact / Suggestions Form */
  contact: {
    title: 'Penny for your thoughts?',
    description:
      'We’d love to hear what you’re thinking about PennyLane or any other feedback you have to share! You can stay anonymous, or if you would like to hear back from us, be sure to include your email.',
    form: {
      /*  Field labels and placeholders */
      first_name: 'First name',
      last_name: 'Last name',
      email: 'Email',
      feedback_message: 'Your feedback (required)',
      submit: 'Submit',
    },
    privacy_markdown: `By submitting this form, you're agreeing to our [privacy policy](/privacy).`,
    success_title: 'Thank you!',
    success_description: '',
    error_message: 'Something went wrong. Please try again later.',
  },
}
