import './GettingInvolvedInfoSections.scss'
import content from '../../content/get-involved'
import SubSectionLayout from '../SubSectionLayout/SubSectionLayout'

const { info_sections } = content

export default function GettingInvolvedInfoSections() {
  return (
    <section>
      {info_sections.map((section, index) => (
        <SubSectionLayout
          key={`getting-involved-info-section-${index}`}
          title={section.title}
          image={section.image}
          description={section.description}
          links={[
            {
              link: section.cta.link,
              link_text: section.cta.label,
            },
          ]}
          image_right_aligned={index % 2 !== 0}
        />
      ))}
    </section>
  )
}
